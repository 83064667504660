@import url('https://fonts.cdnfonts.com/css/koshgarian');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

.roboto {
    font-family: 'Roboto', sans-serif;
}

.koshgarian {
    font-family: 'Koshgarian', sans-serif;
}

.inter {
    font-family: 'Inter', sans-serif;
}

body {
    background-color: #161616;
    /*background: linear-gradient(189deg, rgba(22,22,22,1) 0%, rgba(36,36,36,1) 33%, rgba(22,22,22,1) 66%, rgba(36,36,36,1) 100%);*/


    /*background: rgb(56, 41, 74);
    background: linear-gradient(135deg, rgba(56, 41, 74, 1) 0%, rgba(52, 52, 56, 1) 57%, rgba(56, 41, 74, 1) 100%);*/
    /*Disco mode*/
    /*background: radial-gradient(*/
    /*        circle,*/
    /*        rgba(255, 0, 0, 1) 0%,*/
    /*        rgba(255, 154, 0, 1) 10%,*/
    /*        rgba(208, 222, 33, 1) 20%,*/
    /*        rgba(79, 220, 74, 1) 30%,*/
    /*        rgba(63, 218, 216, 1) 40%,*/
    /*        rgba(47, 201, 226, 1) 50%,*/
    /*        rgba(28, 127, 238, 1) 60%,*/
    /*        rgba(95, 21, 242, 1) 70%,*/
    /*        rgba(186, 12, 248, 1) 80%,*/
    /*        rgba(251, 7, 217, 1) 90%,*/
    /*        rgba(255, 0, 0, 1) 100%*/
    /*);
    /*background-size: 50000% 50000%;*/
    background-size: 500% 500%;
    font-family: 'Inter', sans-serif;
    animation: gradient 15s ease infinite;

}

@keyframes gradient {
    0% {
        background-position: 0 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0 50%;
    }

}

.nav-bg-dark {
    background-color: #161616;
}