.btn-grad-blue {
    background-image: linear-gradient(to right, #314755 0%, #26a0da 51%, #314755 100%);
    margin: 10px;
    padding: 0.75vw 1vw;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    border-radius: 10px;
    display: block;
    border: none;

}

.btn-grad-blue:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
}


.btn-grad-orange {
    background-image: linear-gradient(to right, #d53369 0%, #cbad6d 51%, #d53369 100%);
    margin: 10px;
    padding: 0.75vw 1vw;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    border-radius: 10px;
    display: block;
    border: none;
}

.btn-grad-orange:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
}


.btn-grad-danger {
    background-image: linear-gradient(to right, #a73737 0%, #7a2828 51%, #a73737 100%);
    margin: 10px;
    padding: 0.75vw 1vw;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    border-radius: 10px;
    display: block;
    border: none;
}

.btn-grad-danger:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
}


.btn-grad-dark-red {
    background-image: linear-gradient(to right, #870000 0%, #190A05 51%, #870000 100%);
    margin: 10px;
    padding: 0.75vw 1vw;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    border-radius: 10px;
    display: block;
    border: none;
}

.btn-grad-dark-red:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
}


.btn-grad-purple {
    background-image: linear-gradient(to right, #000000 0%, #53346D 51%, #000000 100%);
    margin: 10px;
    padding: 0.75vw 1vw;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    border-radius: 10px;
    display: block;
    border: none;
}

.btn-grad-purple:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
}


.btn-grad-green {
    background-image: linear-gradient(to right, #52c234 0%, #061700 51%, #52c234 100%);
    margin: 10px;
    padding: 0.75vw 1vw;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    border-radius: 10px;
    display: block;
    border: none;
}

.btn-grad-green:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
}


.btn-grad-electric-aquamarine {
    background-image: linear-gradient(to right, #00C9FF 0%, #92FE9D 51%, #00C9FF 100%);
    margin: 10px;
    padding: 0.75vw 1vw;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    box-shadow: 0 0 20px #eee;
    border-radius: 10px;
    display: block;
}

.btn-grad-electric-aquamarine:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
}




